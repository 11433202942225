var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-page-wrapper"},[_c('div',{staticClass:"d-page-toolbar"},[_c('d-search-input',{on:{"submit":_vm.onSearchSubmit},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"d-btn-1 ml-auto",attrs:{"color":_vm.currentDealerId ? '' : 'grey lighten-1',"depressed":""},domProps:{"textContent":_vm._s(_vm.$t('user.inviteUser'))},on:{"click":_vm.openInviteDialog}})],1),_c('v-data-table',{staticClass:"d-data-table d-data-table--rows-clickable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"page":_vm.pageNumber,"items-per-page":_vm.pageSize,"server-items-length":_vm.totalCount,"disable-sort":"","hide-default-footer":""},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.openEditDialog},scopedSlots:_vm._u([{key:"item.emailAddress",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"d-data-table-cell-icon"},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.emailAddress)+" "),(item.id === _vm.currentUser.id)?_c('span',[_vm._v("("+_vm._s(_vm.$t('user.you'))+")")]):_vm._e()]}},{key:"item.roleId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserRoleById(item.roleId).name)+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('d-active-status-icon',{attrs:{"value":item.isActive}})]}},{key:"item.lastLogonUtc",fn:function(ref){
var item = ref.item;
return [(!item.lastLogonUtc)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('user.pending')))]),_c('span',{staticClass:"px-2"},[_vm._v("-")]),_c('a',{staticClass:"text-decoration-underline text-lowercase",domProps:{"textContent":_vm._s(_vm.$t('user.resendInvitation'))},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.reInviteUser_(item)}}})]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap d-visible-on-row-hover"},[(item.id === _vm.currentUser.id)?_c('d-btn-icon',{attrs:{"type":"lock","color":"black"}}):[_c('d-btn-icon',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.openEditDialog(item)}}}),_c('d-btn-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteUser_(item)}}})]],2)]}}],null,true)}),_c('v-pagination',{staticClass:"my-5",attrs:{"length":_vm.pageCount,"total-visible":15},on:{"input":_vm.onPageInput},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}),_c('user-edit-dialog',{attrs:{"dialog":_vm.editDialog,"user":_vm.editDialog_user},on:{"update:dialog":function($event){_vm.editDialog=$event}}}),_c('user-invite-dialog',{attrs:{"dialog":_vm.inviteDialog},on:{"update:dialog":function($event){_vm.inviteDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }