import { userRole } from '@/constants'


const mixin = {
  computed: {
    userRoles () {
      const items = []

      for (const prop in userRole) {
        const id = userRole[prop]
        let name

        switch (id) {
          case userRole.salesPerson:
            name = this.$t('userRole.salesPerson')
            break
          case userRole.chainAdmin:
            name = this.$t('userRole.chainAdmin')
            break
          case userRole.superAdmin:
            name = this.$t('userRole.superAdmin')
            break
          case userRole.admin:
            name = this.$t('userRole.admin')
            break
          case userRole.user:
            name = this.$t('userRole.user')
            break
        }

        items.push({ id, name })
      }

      return items
    }
  },


  methods: {
    getUserRoleById (id) {
      return this.userRoles.find(x => x.id === id)
    }
  }
}


export default mixin
