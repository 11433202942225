<template>
  <v-dialog
    v-if="editedUser"
    :value="dialog"
    :persistent="isModelChanged()"
    :max-width="400"
    content-class="d-dialog"
    scrollable
    @keydown.esc="escape(closeDialog)"
    @click:outside="escape(closeDialog)"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ user ? $t('user.editUser') : $t('user.createUser') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="escape(closeDialog)"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-select
            v-model="editedUser.roleId"
            :rules="rules.roleId"
            :label="$t('user.edit.role')"
            :items="userRoles"
            item-value="id"
            item-text="name"
            tabindex="1"
            autofocus
          ></v-select>
          <v-checkbox
            v-model="editedUser.isActive"
            :label="$t('user.edit.active')"
            tabindex="1"
          ></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { userRole } from '@/constants'

import objectUtil from '@/utils/object.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import userRoleMixin from '@/mixins/userRole.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  props: {
    user: Object
  },


  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    userRoleMixin
  ],


  data () {
    return {
      editedUser: null,
      defaultUser: {
        dealerId: '',
        roleId: userRole.user,
        isActive: true
      }
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId']),

    rulesToApply () {
      const rule = this.rule

      return {
        roleId: [rule.required()]
      }
    }
  },


  methods: {
    ...mapActions('user', ['createUser', 'updateUser']),

    onDialogOpen () {
      this.editedUser = objectUtil.getObjectCopy(this.user || this.defaultUser)

      this.initWatchedModel(this.editedUser)
      this.resetValidation()
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()
        const user = objectUtil.getObjectCopy(this.editedUser)

        if (user.id) {
          await this.updateUser(user)
          this.showSnackbarSuccess(this.$t('user.userUpdated'))
        } else {
          user.dealerId = this.currentDealerId
          await this.createUser(user)
          this.showSnackbarSuccess(this.$t('user.userCreated'))
        }

        this.closeDialog()
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  }
}
</script>
