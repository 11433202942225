<template>
  <v-dialog
    :value="dialog"
    :max-width="450"
    content-class="d-dialog"
    scrollable
    @keydown.enter="submit"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ $t('user.inviteUser') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="closeDialog"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-text-field
            v-model="email"
            :rules="rules.email"
            :label="$t('user.invite.email')"
            type="email"
            tabindex="1"
            clearable
            autofocus
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('user.invite.invite')"
          :disabled="!email"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import errorMixin from '@/mixins/error.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    errorMixin
  ],


  data () {
    return {
      email: '',
      existingEmails: []
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId']),

    rulesToApply () {
      const rule = this.rule

      return {
        email: [
          rule.required(),
          rule.email(),
          rule.unique(this.existingEmails, this.$t('user.invite.emailExists'))
        ]
      }
    }
  },


  methods: {
    ...mapActions('user', ['inviteUser']),

    onDialogOpen () {
      this.email = ''
      this.errorMessage = ''

      this.resetValidation()
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid) return

      try {
        this.showSnackbarProcessing()

        await this.inviteUser({ dealerId: this.currentDealerId, email: this.email })
        this.showSnackbarSuccess(this.$t('user.invite.invitationSent'))

        this.closeDialog()
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingEmails.push(this.email)
          this.validate(this.rulesToApply)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
