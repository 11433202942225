<template>
  <div class="d-page-wrapper">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        @submit="onSearchSubmit"
      ></d-search-input>
      <v-btn
        v-text="$t('user.inviteUser')"
        class="d-btn-1 ml-auto"
        :color="currentDealerId ? '' : 'grey lighten-1'"
        depressed
        @click="openInviteDialog"
      ></v-btn>
    </div>

    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :page="pageNumber"
      :items-per-page="pageSize"
      :server-items-length="totalCount"
      disable-sort
      hide-default-footer
      class="d-data-table d-data-table--rows-clickable"
      @page-count="pageCount = $event"
      @click:row="openEditDialog"
    >
      <template v-slot:[`item.emailAddress`]="{ item }">
        <v-icon class="d-data-table-cell-icon">mdi-account</v-icon>
        {{ item.emailAddress }}
        <span v-if="item.id === currentUser.id">({{ $t('user.you') }})</span>
      </template>
      <template v-slot:[`item.roleId`]="{ item }">
        {{ getUserRoleById(item.roleId).name }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <d-active-status-icon :value="item.isActive"></d-active-status-icon>
      </template>
      <template v-slot:[`item.lastLogonUtc`]="{ item }">
        <div v-if="!item.lastLogonUtc">
          <span>{{ $t('user.pending') }}</span>
          <span class="px-2">-</span>
          <a
            v-text="$t('user.resendInvitation')"
            class="text-decoration-underline text-lowercase"
            @click.stop.prevent="reInviteUser_(item)"
          ></a>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-no-wrap d-visible-on-row-hover">
          <d-btn-icon v-if="item.id === currentUser.id" type="lock" color="black"></d-btn-icon>
          <template v-else>
            <d-btn-icon type="edit" @click="openEditDialog(item)"></d-btn-icon>
            <d-btn-icon type="delete" @click="deleteUser_(item)"></d-btn-icon>
          </template>
        </div>
      </template>
    </v-data-table>

    <v-pagination
      v-model="pageNumber"
      :length="pageCount"
      :total-visible="15"
      class="my-5"
      @input="onPageInput"
    ></v-pagination>

    <user-edit-dialog
      :dialog.sync="editDialog"
      :user="editDialog_user"
    ></user-edit-dialog>

    <user-invite-dialog
      :dialog.sync="inviteDialog"
    ></user-invite-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { userRole, usersPageSize } from '@/constants'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import userRoleMixin from '@/mixins/userRole.mixin'

import DSearchInput from '@/components/DSearchInput'
import DBtnIcon from '@/components/DBtnIcon'
import DActiveStatusIcon from '@/components/DActiveStatusIcon'
import UserEditDialog from './UserEditDialog'
import UserInviteDialog from './UserInviteDialog'


export default {
  components: {
    DSearchInput,
    DBtnIcon,
    DActiveStatusIcon,
    UserEditDialog,
    UserInviteDialog
  },


  mixins: [
    pageMixin,
    snackbarMixin,
    userRoleMixin
  ],


  data () {
    return {
      search: null,
      pageCount: 0,
      pageNumber: 1,
      pageSize: usersPageSize,
      inviteDialog: false,
      editDialog: false,
      editDialog_user: null
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId', 'previousDealerId', 'shortDealerById']),
    ...mapGetters('user', ['currentUser', 'usersTableItems', 'usersTotalCount', 'currentUserIsChainAdmin']),
    ...mapGetters('chain', ['currentChainId']),

    chainId () {
      return this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
    },

    tableHeaders () {
      let headers = [
        { text: this.$t('user.table.emailAddress'), value: 'emailAddress', cellClass: 'font-weight-medium' },
        { text: this.$t('user.table.dealer'), value: 'dealer.name' },
        { text: this.$t('user.table.role'), value: 'roleId' },
        { text: this.$t('user.table.active'), value: 'isActive' },
        { text: '', value: 'lastLogonUtc', cellClass: 'text-no-wrap', align: 'end', width: 1, sortable: false, filterable: false },
        { text: '', value: 'actions', align: 'end', width: 1, sortable: false, filterable: false }
      ]

      if (this.currentUser.roleId !== userRole.superAdmin) {
        headers = headers.filter(x => x.value !== 'dealer.name')
      }

      return headers
    },

    tableItems () {
      const users = this.usersTableItems(this.currentDealerId).map(user => {
        return {
          ...user,
          dealer: this.shortDealerById(user.dealerId)
        }
      })

      return users
    },

    totalCount () {
      return this.usersTotalCount(this.currentDealerId)
    }
  },


  watch: {
    async currentDealerId () {
      this.restoreInitialUsersTableItems(this.previousDealerId)
      this.search = null
      await this.reloadTableItems(1, true)
    },

    async currentChainId () {
      this.restoreInitialUsersTableItems(this.previousDealerId)
      this.search = null
      await this.reloadTableItems(1, true)
    }
  },


  methods: {
    ...mapActions('user', ['loadUsersTableItems', 'restoreInitialUsersTableItems', 'reInviteUser', 'deleteUser']),

    openInviteDialog () {
      if (!this.currentDealerId) {
        this.showSnackbar(this.$t('user.inviteUserDisabledTooltip'), 3000)
      } else {
        this.inviteDialog = true
      }
    },

    openEditDialog (user) {
      this.editDialog_user = user
      this.editDialog = true
    },

    onSearchSubmit () {
      this.reloadTableItems(1)
    },

    onPageInput () {
      this.reloadTableItems()
    },

    async reloadTableItems (page, isInitial = false) {
      try {
        this.startPageLoading()

        if (page && page !== this.pageNumber) {
          this.pageNumber = page
        }
        const items = await this.loadUsersTableItems({
          chainId: this.chainId,
          dealerId: this.currentDealerId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          search: this.search,
          isInitial
        })

        return items
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    },

    async reInviteUser_ (user) {
      try {
        this.startPageLoading()
        await this.reInviteUser(user)
        this.showSnackbarSuccess(this.$t('user.invite.invitationSent'))
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    },

    async deleteUser_ (user) {
      try {
        const confirm = await this.$confirm(this.$t('user.deleteUserConfirm', user), { trueText: this.$t('delete') })

        if (confirm === true) {
          this.showSnackbarProcessing()
          await this.deleteUser(user)
          this.showSnackbarSuccess(this.$t('user.userDeleted'))

          if (!this.tableItems.length) {
            const page = (this.pageNumber - 1) || 1
            this.reloadTableItems(page)
          }
        }
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  },


  mounted () {
    this.restoreInitialUsersTableItems(this.currentDealerId)
  },


  async created () {
    try {
      this.setPageTitle(this.$t('nav.users'))
      this.startPageLoading()

      await this.loadUsersTableItems({
        chainId: this.chainId,
        dealerId: this.currentDealerId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isInitial: true
      })
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
